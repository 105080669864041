<template>
    <div class="m-5">
        <ErrorCard>
            <template v-slot:content>
                <h1 class="card-title">Sorry, an error has occurred</h1>
                <div class="body-text clarity-margin-top-m clarity-margin-right-s">
                    If you feel this was an error on our part, please <span class="report" @click="$router.push('/securemessaging/message'); contactUs();">report the error</span> to let us know or use the button below to return to your dashboard.
                </div>
                <div class="card-footer clarity-margin-top-m">
                   <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/');" style="max-width: 70% !important;">Return to the Dashboard</Button>
                </div>
            </template>
            <template v-slot:image>
                <img class="bottom" src="@/assets/img/404img.png" alt="ooops">
            </template>
        </ErrorCard>
    </div>
</template>
<script>
import ErrorCard from '@/components/common/ErrorCard'
import {onBeforeRouteUpdate} from "vue-router";
import {computed, ref} from "vue";
import {useStore} from "vuex";


export default {
    setup() {
        const previousRoute = ref('')
        const store = useStore()

        const messageText = computed(() => {
            return 'I would like to report an error when using the clarity website: '+previousRoute.value+'';
        })

        const contactUs = () => {
            store.dispatch('globalMessageTrigger',  {
                subject: 'Website Error ' + previousRoute.value,
                category: 'Feedback',
                recipient: 'Admin Team',
                messagebody: messageText.value
            })
        }

        onBeforeRouteUpdate((to, from, next) => {
            // Access the previous route information
            previousRoute.value = from;
            next();
        });

        return {
            contactUs,
            previousRoute
        }

    },
    components: {
        ErrorCard,
    }
}
</script>
<style scoped>
.spacer {
    height: 40px;
    width: 100%;
}
.bottom {
    padding: 70px 30px 108px 60px;
}
.report {
    color: var(--clarity-light-blue);
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (max-width: 991px) {
    :deep(.card-title) {
        font-size: 32px !important;
        line-height: var(--clarity-line-spacing-32) !important;
    }
}
</style>
