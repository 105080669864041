<template>
    <!-- main container -->
    <div class="clarity-card split-card">

        <!-- Left side contnet panel -->
        <div class="split-card-content">
            <slot name="content"></slot>
        </div>

        <!-- Right side image -->
        <div class="split-card-image">
            <slot name="image"></slot>
        </div>
    </div>
</template>
<style scoped>
.clarity-card {
    width: 100%;
    background: white;
    border-radius: 27px;
    box-shadow: 0px 5px 20px #0000000d;
}
.split-card {
    display: grid;
    grid-template-columns: 5fr 7fr;
}
.split-card-content {
    display: flex;
    flex-direction: column;
    margin: 30px 0px 30px 30px;
}
.split-card-content :deep(.card-title) {
    font-size: 60px;
    font-weight: 300;
    font-family:sans-serif;
    color: var(--clarity-dark-grey);
    text-transform: uppercase;
}
.split-card-content :deep(.card-body) {
    flex-grow: 2;
}
.split-card-content :deep(.p-button) {
    padding: 0px 45px 0px 45px;
}
.split-card-content :deep(.card-footer) {
    padding-bottom: 30px;
}
.split-card-image {
    display: flex;
}
.split-card-image :deep(img) {
    width: 100%;
    max-height: 452px;
    margin-right: 30px;
}
.split-card-image :deep(img.bottom) {
    align-self: flex-end;
    padding-top: 20px;
}
.split-card-image :deep(img.middle) {
    align-self: center;
}
@media only screen and (max-width: 1366px)  {
    .split-card {
        display: grid;
        grid-template-columns: 1fr;
    }
    .split-card-image {
        display: none;
    }
}
</style>
